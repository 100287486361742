<template>
  <div class="header">
      <img src="@/assets/logo.png"  alt="" class="logo" />
      <div class="header_right">
        <ul>
          <li v-for="(item,index) in arr" :key="index" :class="{'select': props.idx == item.key}" @click="goRouter(item)">
            {{ item.name }}
          </li>
          <!-- <li class="select">首页</li>
          <li class="select">关于我们</li> -->
        </ul>
      </div>
  </div>
</template>


<script setup lang="ts">
import { ref,reactive } from "vue";
import { useRouter } from "vue-router";
const router = useRouter();
const props = defineProps({
  idx: Number
});


let arr : any[] = reactive([{
    name: "首页",
    key: 1,
    router: "home"
},{
    name: "关于我们",
    key: 2,
    router: "aboutUs"
}, {
  name: "联系我们",
  key: 3,
  router: "contactUs"
}])
function goRouter(item: object){
  router.push({
      name: item?.router
  });
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.header {
    position: absolute;
    z-index: 99;
    left: 50%;
    top: 40px;
    transform: translateX(-50%);
    width: 1200px;
    height: 58px;
    display: flex;
    justify-content: space-between;
    .logo {
      display: block;
      width: 180px;
      height: 58px;
    }

    .header_right {
     
      ul {
        display: flex;
        align-items: center;
        li {
          cursor: pointer;
          margin-left: 80px;
          list-style: none;
          font-size: 18px;
          color: #FFFFFF;
        }
      }

      .select {
        position: relative;
        color: #05B68C;
      }
      .select::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 3px;
        bottom: -7px;
        background: #05B68C;
      }
    }
  }
</style>
